<template>
  <div>
    <el-tag
      v-for="tag in tags.items"
      :key="tag.uuid"
      closable
      :disable-transitions="false"
      @close="handleClose(tag)"
    >
      {{ tag.name }}
    </el-tag>
    <el-input
      v-if="inputVisible && addBtn"
      ref="saveTagInput"
      v-model="inputValue"
      class="input-new-tag"
      size="small"
      @keyup.enter.native="handleInputConfirm"
      @blur="handleInputConfirmBlur"
    />
    <el-button
      v-else
      class="button-new-tag"
      size="small"
      @click="showInput"
    >
      + New Tag
    </el-button>
  </div>
</template>

<script>
import { Tag } from '@timeragent/core';

export default {
  props: {
    tags: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small',
    },
    addBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',
    };
  },
  methods: {
    async handleClose(tag) {
      await tag.delete();
      this.tags.items = this.tags.items.filter(t => t.uuid !== tag.uuid);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      const { inputValue } = this;

      if (inputValue) {
        const tag = new Tag({ name: inputValue });

        tag.save();
        this.tags.push(tag);
      }
      this.inputValue = '';
    },
    handleInputConfirmBlur() {
      this.handleInputConfirm();
      this.inputVisible = false;
    },
  },
};
</script>

<style scoped>
.el-tag {
  margin: 5px 10px 5px 0;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  margin-top: 5px;
  width: 90px;
  vertical-align: top;
}
</style>
